import React from 'react';

import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import MercadoLibreLogo from "../../../components/images/mercadolibre"
import SectionNavigate from '../../../components/widgets/sectionNavigate';
import ImageFooted from '../../../components/widgets/imageFooted';
import { IMG_Shopify } from '../../../components/images/docs/shopify';
import { IMG_Mercadolibre } from '../../../components/images/docs/mercadolibre';

type DataProps = {
    site: {
        buildTime: string
    }
}

const ShopifyClavesDefault: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isEcommerce={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ShopifyDocs' className="_DOCS">
            <div className="integrationLogo">
                <MercadoLibreLogo />
            </div>
            <h2>
                Claves Predeterminadas
            </h2>
            <p>
                Es importante explicar para que requiere el SAT las claves para aclarar dudas y desmentir algunos conceptos. <br />
            </p>
            <p>
                El <b>propósito principal de las claves del SAT es estadístico </b> para ellos, muchos contribuyentes tienen la apreciación equivocada que al no usar claves precisas pueden arriesgarse a multas por parte del SAT.
            <br />
                Esto no es del todo cierto, <b>si bien existen multas por el mal uso de claves, estas deben de ser un completo abuso en su definición</b>.
            </p>
            <p>
                Para el SAT, el siguiente ejemplo podría considerarse un abuso: <b>Vender un playstation y declararlo como "Servicios de enfermería" cuando el contribuyente tiene declarado su actividad económica como "Venta de productos y equipos"</b>.
            </p>
            <p>
                El siguiente ejemplo, <b>NO es considerado un abuso y no generará problema alguno: Vender una funda de celular y registrarla como "Teléfonos móviles (43191501)"</b>
            </p>
            <p>
                Una regla a seguir al momento de configurar tus claves es mantenerse lo mas alineado al inventario general o claves genéricas cercanas a tu actividad económica. <br />
                En nuestra plataforma buscamos que sea lo más sencillo configurar claves indistinto de la cantidad de productos que manejes, <b>Es importante que al querer ser detallado con las claves, sea una decisión propia entre ustedes (y su contador
                donde aplique) y no motivarse por ansiedad por cumplir producto por producto.</b>
            </p>
            <p>
                Hay dos etapas para configurar las claves del SAT de tus productos:
            </p>
            <ol>
                <li>
                    <p><b>Claves predeterminadas: </b> una sola clave compartida entre todos tus productos que no tengan una configuración particular.
                    <br />Esta configuración es obligatoria y <span className="accent">la trataremos en esta sección </span>

                    </p>
                </li>
                <li>
                    <p><b>Claves por producto: </b> configuración de claves independiente para cada producto. Es una configuración opcional cuando tus productos son muy diferentes entre si. <br />
                        <span className="small quote"> Si ya realizaste la configuración de claves predeterminadas y deseas saber como configurar todos tus productos puedes ir <Link to="../claves-sat">aquí</Link></span>
                    </p>
                </li>
            </ol>
            <h3>
                Configuración de Claves Predeterminadas 
            </h3>
            <ImageFooted explanation={`Dentro de "Mi configuración" busca por texto la clave de producto y unidad, cuando encuentres la ideeal DA CLICK a la opción mostrada para seleccionarla.`}>
                <IMG_Mercadolibre file={`clavesPredet`} />
            </ImageFooted>
            <p>
                Una vez hayas realizado los cambios, da click en el ícono de guardar para confirmarlos.
            </p>
            <SectionNavigate next={`/ecommerce/mercadolibre/config-autofact`} previous={`/ecommerce/mercadolibre/certificados`}/>
        </div>
    </Layout>
)

export default ShopifyClavesDefault;